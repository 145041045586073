import React from "react"
import LayoutComponent from "../../components/Layout.js"
import Simulatorer from "../../components/Simulatorer.js"
import Knap from "../../components/Knap.js"


const SimulatorerPage = () => (
    <LayoutComponent>
        <h1>Simulatorer</h1>
        <p></p>
        <h1>Våre leverandører</h1>
        <Simulatorer />
        <Knap />
    </LayoutComponent>
)
export default SimulatorerPage